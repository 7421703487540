import Footer from "../guest/partials/Footer";
import { Helmet } from 'react-helmet';
const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Prime e-Gift</title>
        <meta name="description" content="Read the privacy policy of Prime e-Gift, outlining our data collection, usage, and protection practices." />
        <meta name="keywords" content="privacy policy, data protection, personal information, Prime e-Gift" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.primeegiftcard.com/privacy-policy" />
        <meta property="og:title" content="Privacy Policy - Prime e-Gift" />
        <meta property="og:description" content="Read the privacy policy of Prime e-Gift, outlining our data collection, usage, and protection practices." />
        <meta property="og:url" content="https://www.primeegiftcard.com/privacy-policy" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy - Prime e-Gift" />
        <meta name="twitter:description" content="Read the privacy policy of Prime e-Gift, outlining our data collection, usage, and protection practices." />
      </Helmet>
      <section>
        <div className="container p-4">
          <div className="WordSection1">
            <p
              className="MsoNormal sub-heading"
              style={{
                marginTop: "6.0pt",
                marginRight: "0cm",
                marginBottom: "6.0pt",
                marginLeft: "0cm",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "2rem",
                  color: "#333333",
                  letterSpacing: ".75pt",
                }}
              >
                Privacy Policy
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Updated - 17/06/2022
                <br />
                <br />
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                This Privacy Policy describes the privacy practices of Prime
                e-Gift, its subsidiaries, and affiliates (&quot;Prime”) in
                connection with its website located at www.primeegiftcard.com,
                Prime Merchant Dashboard, it's Prime Gift Card App and it's iOS
                &amp; Android Apps, collectively the (&quot;Site&quot;). Please
                review it carefully. Your use of the Site constitutes your
                agreement to this Privacy Policy. Please also review the&nbsp;
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#1E95D8" }}
              >
                Terms of Service
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                &nbsp;which, in addition to this Privacy Policy, also govern
                your use of the Site and Prime e-Gift’s services.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  1. WHAT KINDS OF INFORMATION DOES PRIME COLLECT?
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  1.1 PERSONALLY IDENTIFIABLE INFORMATION
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Personally Identifiable Information (&quot;PII”) is information
                that identifies a specific user. It may include your first name,
                last name, picture, address, e-mail address, telephone number,
                Tin Number, Business Registration Number, debit card
                information, bank account, or other payment service account
                information, social network account information such as Facebook
                account information, or any other information that specifically
                identifies a user.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                When you engage in certain activities on the Site, Prime must
                collect PII from you to complete your order or otherwise process
                your transaction. Activities that require Primeto collect PII
                include creating a Prime account, purchasing a Prime gift card,
                redeeming a Prime gift card, submitting content (such as
                comments, reviews, photographs, or videos) to the Site,
                contacting Primewith a question or concern, completing a survey,
                sending best practices and promotionals, email offers and
                redeeming or signing up for a special offer. You may access the
                Site without engaging in any of these activities. However, if
                you choose to engage in any of these activities, Prime will
                collect PII from you. Some of the PII is mandatory and some is
                optional based upon the certain activity.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime does not allow other parties to collect PII about an
                individual consumer's online activities over time and across
                different websites when users are on Prime gift website.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  1.2 INFORMATION ABOUT HOW YOU USE THE SITE
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Like most websites, Prime uses cookies, web beacons, embedded
                scripts, and similar technologies to collect information about
                how you use the Site. The information collected may include
                information that your browser or device sends to our servers
                when you visit the Site, such as your IP address (which may tell
                us generally where you are located), the type of browser or
                device that you used to access the Site, the web page that led
                you to the Site, the search terms that you typed into a search
                engine to find the Site, and the web page you went to after
                leaving the Site. The information collected may also include the
                web pages you visited on the Site, the advertisements that were
                displayed, and the advertisements or links that you viewed or
                clicked on. Prime e-Gift’s servers may collect similar
                information while you are logged on to the Site. The information
                collected may be anonymous or it may be associated with you.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Emails that Prime sends to you may include a web beacon or
                similar technology that tells Prime whether you have received or
                opened the email or clicked on a link in the email.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                The Site may include content, links, or buttons from third party
                companies. Prime may collect information about your use of these
                features. In addition, when you view or use a feature from a
                third party company, or the web page on which it appears,
                information from your browser may be sent to the third party
                company. You should review the third party company’s privacy
                policy carefully to learn about its privacy practices.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  1.3 INFORMATION FROM SOCIAL NETWORKING SERVICES
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                If you access the Site from a social networking service such as
                Facebook, we will collect the information automatically provided
                by the social networking service under its applicable policies
                and any additional information that you permit the service to
                provide. Prime does not control these social networking services
                and is not responsible for the information they provide. Before
                accessing the Site from a social networking service, you should
                review its privacy policy carefully to learn about its privacy
                practices.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  2. HOW DOES PRIME USE THE INFORMATION THAT IT COLLECTS?
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime uses the PII and other information that it collects to
                activate and operate your Prime account, authenticate and
                identify you for security and other purposes, fulfill your
                orders for products and services, communicate with you about
                your account or orders, respond to your feedback, questions, or
                concerns, contact you about new products or services or for
                other marketing purposes, administer contests, sweepstakes,
                surveys, and special offers, diagnose and troubleshoot problems
                with the Site, improve your experience with the Site, tailor
                advertising content to your interests, track information you
                have provided to us, such as your user preferences and
                preferences regarding receiving emails and other communications,
                and to analyze site usage for market research and other
                purposes.
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime may also use the PII and other information that it
                collects to enforce its Terms of Use, Terms of Sale, or Privacy
                Policy, resolve disputes with you or a recipient of a gift card
                sent by you, prevent fraud or infringement of intellectual
                property rights, cooperate with law enforcement, and to comply
                with the law.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  2.1 HOW DOES PRIME STORE MY DATA
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime stores your encrypted information on its server. Other
                data in the form of images and sounds are stored on Amazon AWS(a
                third party server) and referenced in our database. Prime fully
                owns an account with Amazon AWS and fully controlls the account.
                Your images are safe with Prime. You have the right to request
                for deletion of your personal data from our system.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  2.2 WHY DOES PRIME UPLOAD CONTACT
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime stores your encrypted information on its server as
                mentioned above. Occasionally, if you decide to buy a gift card
                for someone or a loved one, prime allows you to select a contact
                from your phone contacts. The contact is saved on Prime for the
                purpose of keeping records of the gifted person and its
                associated card account. It's safe to upload a contact during
                card purchase and Prime will no way sell or disclose your
                contacts to any third party. You have the right to request for
                deletion of your personal data and associated contacts uploaded
                to our system.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  2.3 WHY DOES PRIME UPLOAD IMAGES
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime optionally allows users to upload an image as their
                profile picture. Images uploaded to Prime are stored on Amazon
                and referenced in our database. It's safe to upload an image
                which is not mandatory on our platform and Prime will no way
                sell or disclose your image(s) to any third party. You have the
                right to request for deletion of your personal data and
                including images uploaded to our system.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  3. WILL PRIME SHARE MY INFORMATION?
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime will not rent, sell or lease your personal information to
                any unaffiliated person or entity without your express consent.
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime may share your name, contact information, and personal
                message with the recipient of your gift card and specific
                businesses that have contracted to offer Prime services.
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime works with other companies that help it run its business.
                These companies (&quot;Service Providers”) may provide services
                to Prime such as processing payments, delivering customer or
                technical support, sending emails on Prime e-Gift’s behalf,
                fulfilling orders, administering contests, Affiliates Programs,
                and special offers, analyzing Site usage and other data, and
                running online advertising campaigns that display advertisements
                on the Site or on third party websites. These Service Providers
                may have access to your PII and other information that Prime
                collects, but they are not permitted to use it for their own
                purposes without Prime expressed and authorized permission.
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime may share, including with third party business partners
                and advertisers, information that does not specifically identify
                you, such as statistical information about usage of the Site and
                other aggregate information.
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime may share your PII and other information that it collects:
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "10.0pt", color: "#333333" }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                If it is required to do so in response to a subpoena or a court
                order or to comply with the law.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "10.0pt", color: "#333333" }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                To enforce its Terms of Use, Terms of Sale, or Privacy Policy,
                or to resolve a dispute with you or a recipient of a Prime gift
                card sent by you.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "10.0pt", color: "#333333" }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                If it has a good faith belief that disclosure is necessary to
                prevent fraud, intellectual property infringement, to prevent or
                respond to an actual or threatened security breach, or to
                otherwise protect the rights or safety of others.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "10.0pt", color: "#333333" }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                If Prime is merged with or acquired by another company, or if
                the Site or all or a substantial portion of Prime e-Gift’s
                assets is acquired by another company, your PII and other
                information may be transferred as part of the transaction.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "10.0pt", color: "#333333" }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                If you have agreed to the sharing.
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Some portions of the Site may permit you to submit content for
                other users to see, such as comments, reviews, e-cards,
                photographs, and videos. Content submitted to these portions of
                the Site will be public and not subject to this Privacy Policy.
                Please be careful when you submit content to these portions of
                the Site and do not submit anything that you do not wish to be
                public. Note that when you post content to these portions of the
                Site, your name and email address may be displayed.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  3.1 INTERNET PROTOCOL (IP) ADDRESS
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                We may use your IP address to help diagnose problems with our
                server, and to administer our Web site. Your IP address is used
                to help identify you for the duration of a session and to gather
                broad demographic information.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  3.2 CLICKSTREAM
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                As you browse the Internet, a trail of electronic information is
                left at each Web site you visit. This information, which is
                sometimes called &quot;clickstream&quot; data, can be collected
                and stored by a Web site's server. Clickstream data can tell us
                the type of computer and browsing software you use, the address
                of the Web site from which you linked to the Prime web site, and
                in some instances, your e-mail address. We may use clickstream
                data to determine how much time visitors spend on each page of
                our site and how they navigate throughout our site. We will only
                use this information to improve the Prime web site. Any
                collection or use of clickstream data will be anonymous and
                aggregate, and will not contain any personal information.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  4. HOW DOES PRIME PROTECT THE INFORMATION IT COLLECTS?
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime understands the importance of protecting your PII. Prime
                takes reasonable physical, administrative, and technical steps
                to secure the PII you provide. For example, Prime secures its
                facilities, network, and servers, encrypts financial information
                and transmits it securely, and restricts access to PII to
                authorized personnel only. However, no security controls are
                100% effective and Prime cannot guarantee or warrant the
                security of your PII.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  YOUR PRIVACY CHOICES
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  PROMOTIONAL EMAILS
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                If you do not want to receive Prime promotional emails you may
                opt out by clicking the &quot;Unsubscribe” link at the bottom of
                any Prime promotional email. You can also opt out at any time by
                contacting us at www.primeegiftcard.com or sending us an email
                at&nbsp;
              </span>
              <span lang="EN-US">
                <a href="mailto:info@giftfly.com">
                  <span
                    style={{
                      fontSize: "13.5pt",
                      color: "#1E95D8",
                      textDecoration: "none",
                    }}
                  >
                    info@primergift.com
                  </span>
                </a>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                {" "}
                or calling us at (024) 711-7448 and telling us that you do not
                wish to receive promotional emails.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                There are certain emails that we must send to you regarding your
                account, account activity, and other business operations. You
                may not opt out of these emails.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "22.5pt",
                textIndent: "-18.0pt",
              }}
            >
              <span
                lang="EN-US"
                style={{
                  fontSize: "10.0pt",
                  color: "#1E95D8",
                  textTransform: "uppercase",
                  letterSpacing: ".75pt",
                }}
              >
                ·
                <span style={{ fontSize: "7.0pt" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  COOKIES
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "11.25pt",
                marginLeft: "22.5pt",
              }}
            >
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                &quot;Cookies&quot; are pieces of information that are placed on
                an individual's computer hard drive to enable the individual to
                more easily communicate and interact with Web sites. Prime may
                use cookies to customize your experience on the Prime web site.
                We may use cookies to deliver content specific to your
                interests, save your password (if you have registered with us)
                so you don't have to re-enter it each time you visit our site,
                and for other purposes. You may, however, disallow receiving
                cookies at any time through your web browser. Prime will never
                use cookies to retrieve information from a computer that is
                unrelated to the Prime web site or your interaction with the
                Prime Web site.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  5. HOW DO WE RESPOND TO DO NOT TRACK SIGNALS
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                We do not support the Do Not Track browser setting. Do Not Track
                (or DNT) is a preference you can set in your browser to let the
                websites you visit know that you do not want them collecting
                certain information about you.
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                For more details about Do Not Track, including how to enable or
                disable this preference, visit this Do Not Track webpage:&nbsp;
              </span>
              <span lang="EN-US">
                <a
                  href="https://termsfeed.com/do-not-track"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span
                    style={{
                      fontSize: "13.5pt",
                      color: "#1E95D8",
                      textDecoration: "none",
                    }}
                  >
                    https://termsfeed.com/do-not-track
                  </span>
                </a>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                .
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  6. UPDATING OR CORRECTING YOUR INFORMATION
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                You can update or correct your PII by accessing your account on
                the Site. You can also contact us at&nbsp;
              </span>
              <span lang="EN-US">
                <a href="https://www.giftfly.com">
                  <span
                    style={{
                      fontSize: "13.5pt",
                      color: "#1E95D8",
                      textDecoration: "none",
                    }}
                  >
                    www.primeegiftcard.com
                  </span>
                </a>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                {" "}
                or sending us an email at&nbsp;
              </span>
              <span lang="EN-US">
                <a href="mailto:info@giftfly.com">
                  <span
                    style={{
                      fontSize: "13.5pt",
                      color: "#1E95D8",
                      textDecoration: "none",
                    }}
                  >
                    info@primergift.com
                  </span>
                </a>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                {" "}
                or calling us at (024) 711-7448. Please tell us your name,
                address, and the PII that you would like to update or correct.
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                If you would like to delete your account or PII, please contact
                us at www.Prime gift.com/privacy with your request. Please note
                that for legal and business reasons, we may need to retain
                certain records and information. In addition, some information
                may remain on back-up systems.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  7. LINKS TO OTHER WEBSITES
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                This Privacy Policy only addresses privacy practices for this
                Site. The Site may contain links to other websites, including
                third party websites. This Privacy Policy does not govern such
                other websites and Prime is not responsible for the privacy
                practices of other websites. When you visit another website you
                should review carefully the privacy policy for that website.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  8. CHANGES TO THIS PRIVACY POLICY
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Prime may change this Privacy Policy at any time, for any
                reason, and without notice, by posting the amended Privacy
                Policy on the Site. The revised Privacy Policy will become
                effective on the date of posting. Your continued use of the Site
                after posting constitutes your agreement to the revised Privacy
                Policy. Prime encourages you to check the Privacy Policy
                frequently for the latest information on its privacy practices.
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "15.0pt",
                marginRight: "0cm",
                marginBottom: "7.5pt",
                marginLeft: "0cm",
              }}
            >
              <b>
                <span
                  lang="EN-US"
                  style={{
                    fontSize: "10.5pt",
                    color: "#1E95D8",
                    textTransform: "uppercase",
                    letterSpacing: ".75pt",
                  }}
                >
                  9. HOW TO CONTACT PRIME E-GIFT
                </span>
              </b>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                If you have any questions or concerns about this Privacy Policy,
                please contact us by e-mail at&nbsp;
              </span>
              <span lang="EN-US">
                <a href="mailto:info@primergift.com">
                  <span style={{ fontSize: "13.5pt" }}>
                    info@primergift.com
                  </span>
                </a>
              </span>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                &nbsp;or call us at (024) 711-7448.
              </span>
            </p>

            <p className="MsoNormal" style={{ marginBottom: "11.25pt" }}>
              <span
                lang="EN-US"
                style={{ fontSize: "13.5pt", color: "#333333" }}
              >
                Effective date: 10/15/2020
              </span>
            </p>

            <p className="MsoNormal">
              <span lang="EN-US">&nbsp;</span>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Privacy;
