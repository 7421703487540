import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row padding-row">
          <div className="col-md-3">
            <img src="/assets/images/logo.png" width="25%" alt="" />
            <div className="footer-text mt-3">
              eGift cards are a great way for merchants to offer their valued
              customers an alternative way to send gifts.
            </div>
            <div className="mt-3 subtitle2 text-left">Get the app!</div>
            <div className="d-flex mt-3">
              <div>
                <a href="https://apps.apple.com/us/app/prime-e-gift/id1546084735" target="_blank">
                  <img src="/assets/images/ios.png" alt="" />
                </a>
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.jackbuild.prime.customer" target="_blank">
                  <img src="/assets/images/andriod.png" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-3">
            <h3 className="footer-text-title">Want to know more ?</h3>

            <ul className="footer-links text-left1">
              {/* <li>
                <a href="#">FAQs</a>
              </li> */}
              <li>
                <a href="/customer-care">Contact Us</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
              {/* <li>
                <a href="#">Careers</a>
              </li> */}
              {/* <li>
                <a href="#">Board of Directors</a>
              </li>
              <li>
                <a href="#">Retailer Enquiries</a>
              </li>
              <li>
                <a href="#">Gift Tracker</a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-3">
            <h3 className="footer-text-title">More About Us</h3>

            <ul className="footer-links text-left1">
              {/* <li>
                <a href="#">Terms of Sale</a>
              </li> */}
              <li>
                <Link to="/terms-of-use">Terms of Service</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/prime-usage">Prime Usage</Link>
              </li>
              {/* <li>
                <a href="#">Promotion Terms</a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-2">
            <h3 className="footer-text-title">Social Links</h3>

            <ul className="footer-links text-left1">
              {/* <li>
                <a href="#">Blog</a>
              </li> */}
              <li>
                <a href="https://web.facebook.com/primeegiftcard">Facebook</a>
              </li>
              {/* <li>
                <a href="#">Instagram</a>
              </li>
              <li>
                <a href="#">LinkedIn</a>
              </li> */}
              <li>
                <a href="https://twitter.com/primeegiftcard">Twitter</a>
              </li>
              {/* <li>
                <a href="#">Youtube</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-4 footer">
        <div className="row">
          <div className="text-center bottom-font py-3">
            &copy;2020 All Rights Reserved by Prime Plus
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
