import GeneralTabs from "./primeusageComponent/GeneralTabs";
import NewMap from "../NewMap";
import AboutPrime from "./primeusageComponent/AboutPrime";
import GiftCardTypes from "./primeusageComponent/GiftCardTypes";
import BuyGiftCards from "./primeusageComponent/BuyGiftCards";
import RedeemGiftCards from "./primeusageComponent/RedeemGiftCards";
import { Helmet } from "react-helmet";
const PrimeUsage = () => {
  return (
    <>
    <Helmet>
        <title>How to Use Prime e-Gift - Prime Usage</title>
        <meta name="description" content="Learn how to effectively use Prime e-Gift services, from purchasing gift cards to redeeming them seamlessly." />
        <meta name="keywords" content="Prime usage, how to use Prime e-Gift, digital gift cards, redeem gift cards, gift card guide" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.primeegiftcard.com/prime-usage" />
        <meta property="og:title" content="How to Use Prime e-Gift - Prime Usage" />
        <meta property="og:description" content="Learn how to effectively use Prime e-Gift services, from purchasing gift cards to redeeming them seamlessly." />
        <meta property="og:url" content="https://www.primeegiftcard.com/prime-usage" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How to Use Prime e-Gift - Prime Usage" />
        <meta name="twitter:description" content="Learn how to effectively use Prime e-Gift services, from purchasing gift cards to redeeming them seamlessly." />
      </Helmet>
    <div className="container">
      <div className="col-lg-12 mt-4">
        <div className="prime-usage">
          <h1>Prime Usage</h1>
        </div>
      </div>
      <div className="mt-3">
        <div className="col-lg-12">
          <GeneralTabs />
        </div>
      </div>
      <div className="mt-3">
        <div className="col-lg-12">
          <div className="tab-content" id="pills-tabContent">
            <AboutPrime />
            <GiftCardTypes />
            <BuyGiftCards />
            <RedeemGiftCards />
            <div
              className="card card-back tab-pane fade mb-5"
              id="pills-merchants"
              role="tabpanel"
              aria-labelledby="pills-merchants-tab"
            >
              <NewMap />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default PrimeUsage;
